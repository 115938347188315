import { Toast, Modal } from "antd-mobile";
import { Fragment, useState } from "react";
import { login, register, unsubscribed } from "../data";
import styles from "./index.module.css";
import { ExclamationCircleFill } from 'antd-mobile-icons'

export interface UserToken {
  /**
   * 登录账号
   */
  mobile_no: string;
  /**
   * 令牌
   */
  token: string;
  /**
  * 订阅状态
  */
  status: number
}

export interface URLParam {
  id?: string|null;
  tid?: string|null;
  msisdn?: string|null;
  pmsisdn?: string|null;
  status?: string|null;
  code?: string|null;
  price?: string|null;
  carrier?: string|null;
  sid?: string|null
}

class URLParams {
  id?: string|null;
  tid?: string|null;
  msisdn?: string|null;
  pmsisdn?: string|null;
  status?: string|null;
  code?: string|null;
  price?: string|null;
  carrier?: string|null;
  sid?: string|null
}

function get_url_parmas(){
  const url = window.location.search
  const queryParams = new URLSearchParams(url) 
  const urlparams = new URLParams() 
  const parmas:any[]=[]
  if (queryParams.get("id") !== null &&queryParams.get("id") !== undefined){
    urlparams.id = queryParams.get("id")
  }
  parmas.push(urlparams.id)
  if (queryParams.get("tid") !== null && queryParams!.get("tid") !== undefined){
    urlparams.tid = queryParams.get("tid")
  }
  parmas.push(urlparams.tid)
  if (queryParams.get("msisdn")!==null&&queryParams.get("msisdn")!==undefined){
    urlparams.msisdn = queryParams.get("msisdn")
  }
  parmas.push(urlparams.msisdn)
  if (queryParams.get("pmsisdn")!==null&&queryParams.get("pmsisdn")!==undefined){
    urlparams.pmsisdn = queryParams.get("pmsisdn")
  }
  if (queryParams.get("status")!==null&&queryParams.get("status")!==undefined){
    urlparams.status = queryParams.get("status")
  }
  parmas.push(urlparams.pmsisdn)
  parmas.push(urlparams.status)
  if (queryParams.get("code")!==null&& queryParams.get("code")!==undefined){
    urlparams.code = queryParams.get("code")
  }
  parmas.push(urlparams.code)
  if (queryParams.get("price")!==null&&queryParams.get("price")!==undefined){
    urlparams.price = queryParams.get("price")
  }
  parmas.push(urlparams.price)
  if (queryParams.get("carrier")!==null||queryParams.get("carrier")!==undefined){
    urlparams.carrier = queryParams.get("carrier")
  }
  parmas.push(urlparams.carrier)
  if (queryParams.get("sid")!==null||queryParams.get("sid")!==undefined){
    urlparams.sid = queryParams.get("sid")
  }
  parmas.push(urlparams.sid)
  
  return urlparams
}

// 弹窗
function pop_up(){
  // --- 初始化cid和clickid
  const cid = "123"
  let clickid = ""
  let _sf = ""
  let _ym = ""
  // 年月日时分秒毫秒
  const time = new Date()
  let ym = time.toLocaleString().split(" ")[0].split("/")
  let sf = time.toLocaleString().split(" ")[1].split(":")
  ym.forEach((item:string) => {
    _ym = _ym + item
  });
  sf.forEach((item:string) => {
    _sf = _sf + item
  });
  let _ms = time.getMilliseconds()
  clickid = clickid + _ym + _sf + _ms
  let url = `https://plm.gameplus.vip/lp/index.html?cid=${cid}&clickid=${clickid}`
  Modal.alert({
    header: (
      <ExclamationCircleFill
        style={{
          fontSize: 64,
          color: 'var(--adm-color-warning)',
          alignContent: 'center'
        }}
      />
    ),
    title: 'Oops..',
    content: (
      <>
        <div>Wygląda na to, że nie subskrybujesz naszej usługi</div>
      </>
    ),
    onConfirm:()=>{
      console.log("关闭弹窗")
      window.location.href = url 
    },
    showCloseButton: true,
    confirmText: "Idź Subskrybuj"

  })
  
}

// 刷新页面
function _login(){
  const parmas = get_url_parmas()
  console.log("parmas--->",parmas)
  var mobile_number:string = ""
  const password = "123456"
  // pmsisdn && tid 存在
  if (parmas.pmsisdn && parmas.tid){
    // mobile_number = String(parmas.pmsisdn).slice(2)
    mobile_number = String(parmas.pmsisdn)
    login(mobile_number, password, (msg, token, registered) => {
      if (token) {
        let status = token.status;
        // 该用户状态
        if (!status){
          pop_up()
        }else{
          Toast.show({
            icon: "success",
            content: "Login Success",
          });
          window.localStorage.setItem("token", JSON.stringify(token));
        }
      } else {
        if (!registered){
          // 没有注册手机号，需要存入subscription_abnormal
          unsubscribed(parmas).then((res)=>{
            console.log("unsubscribed->res", res)
          })
        }
        Toast.show({
          icon: "fail",
          content: msg,
        });
      }
    });
  }

}
_login()


function Login(props: {
  onClose: () => void;
  setToken: React.Dispatch<React.SetStateAction<UserToken | undefined>>;
}) {
  const [form, setForm] = useState({
    mobile_number: "",
    password: "",
    confirm_password: "",
  });
  const [formType, setFormType] = useState<"Zaloguj się" | "Zarejestruj się">("Zaloguj się");
  const [error, setError] = useState(false);

  function handleClick(){
    setError(false);
    if (formType === "Zaloguj się") {
      form.password = "123456"
      if (form.mobile_number === "") {
        setError(true);
        return;
      }
      form.mobile_number = 48 + form.mobile_number

      login(form.mobile_number, form.password, (msg, token) => {
        if (token) {
          let status = token.status
          if (!status){
            pop_up()
          }else{
            Toast.show({
              icon: "success",
              content: "Login Success",
            });
            props.setToken(token);
            window.localStorage.setItem("token", JSON.stringify(token));
            props.onClose();
          }
        } else {
          Toast.show({
            icon: "fail",
            content: msg,
          });
        }
      });
    } else {
      if (
        form.mobile_number === "" ||
        form.password.length < 6 ||
        (form.confirm_password.length < 6 &&
          form.password !== form.confirm_password)
      ) {
        setError(true);
        return;
      }
      register(form.mobile_number, form.password, (code, msg) => {
        
        Toast.show({
          icon: code === 200 ? "success" : "fail",
          content: msg,
        });
        if (code === 200) {
          props.onClose();
        }
      });
    }
    setForm({ mobile_number: "", password: "", confirm_password: "" });
  }

  return (
    <div className={styles["login-content"]}>
      <div className={styles["login-content-close"]} onClick={props.onClose}>
        <img src="/assets/close.svg" alt=""></img>
      </div>
      <div className={styles["login-content-content"]}>
        <p>{formType}</p>
        <div className={styles.label}>Numer telefonu komórkowego</div>
        <span
        style={{color: "#ffff", position: "absolute", marginLeft: "10px", bottom: "41%"}}
        >+48</span>
        <input
          className={styles["login-content-input"]}
          value={form.mobile_number}
          placeholder="Twój numer telefonu komórkowego"
          onInput={(ele) =>
            setForm({
              ...form,
              ...{ mobile_number: (ele.target as HTMLInputElement)?.value },
            })
          }
        ></input>
        {form.mobile_number === "" && error && (
          <div className={styles.error}>Numer telefonu komórkowego nie może być pusty</div>
        )}
        {/* <div className={styles.label}>Hasło</div>
        <input
          className={styles["login-content-input"]}
          type="password"
          value={form.password}
          placeholder="hasło"
          onInput={(ele) =>
            setForm({
              ...form,
              ...{ password: (ele.target as HTMLInputElement)?.value },
            })
          }
        ></input>
        {form.password.length < 6 && error && (
          <div className={styles.error}>
              Przynajmniej 6 znaków
          </div>
        )}
        {formType === "Zarejestruj się" && (
          <Fragment>
            <div className={styles.label}>Potwierdź hasło</div>
            <input
              className={styles["login-content-input"]}
              type="password"
              value={form.confirm_password}
              placeholder="Potwierdź hasło"
              onInput={(ele) =>
                setForm({
                  ...form,
                  ...{
                    confirm_password: (ele.target as HTMLInputElement)?.value,
                  },
                })
              }
            ></input>
            {form.confirm_password.length < 6 && error && (
              <div className={styles.error}>
                  Przynajmniej 6 znaków
              </div>
            )}
            {form.password !== "" &&
              form.confirm_password !== "" &&
              form.password !== form.confirm_password && (
                <div className={styles.error}>Hasło nie pasuje</div>
              )}
          </Fragment>
        )} */}
        <div
          className={styles["login-content-confirm"]}
          onClick={handleClick}
          // onClick={() => {
          //   setError(false);
          //   if (formType === "Zaloguj się") {
          //     if (form.mobile_number === "" || form.password.length < 6) {
          //       setError(true);
          //       return;
          //     }

          //     login(form.mobile_number, form.password, (msg, token) => {
          //       if (token) {
          //         Toast.show({
          //           icon: "success",
          //           content: "Sukces",
          //         });
          //         props.setToken(token);
          //         window.localStorage.setItem("token", JSON.stringify(token));
          //         props.onClose();
          //       } else {
          //         Toast.show({
          //           icon: "fail",
          //           content: msg,
          //         });
          //       }
          //     });
          //   } else {
          //     if (
          //       form.mobile_number === "" ||
          //       form.password.length < 6 ||
          //       (form.confirm_password.length < 6 &&
          //         form.password !== form.confirm_password)
          //     ) {
          //       setError(true);
          //       return;
          //     }
          //     register(form.mobile_number, form.password, (code, msg) => {
          //       Toast.show({
          //         icon: code === 200 ? "success" : "fail",
          //         content: msg,
          //       });
          //       if (code === 200) {
          //         props.onClose();
          //       }
          //     });
          //   }
          //   setForm({ mobile_number: "", password: "", confirm_password: "" });
          // }}
        >
          {formType === "Zaloguj się" ? "Zaloguj się" : "Zarejestruj się"}
        </div>
        {/* <div className={styles.gameplus}>
          {formType === "Zaloguj się"
            ? "Jesteś nowy w Gameplus?"
            : "Masz już konto?"}
        </div>
        <div
          className={styles.signup}
          onClick={() => {
            setFormType(formType === "Zaloguj się" ? "Zarejestruj się" : "Zaloguj się");
            setForm({ mobile_number: "", password: "", confirm_password: "" });
            setError(false);
          }}
        >
          {formType === "Zaloguj się" ? "Zarejestruj się za darmo" : "Zaloguj się"}
        </div> */}
      </div>
    </div>
  );
}

export default Login;
