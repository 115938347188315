import { useRef } from "react";
import { Swiper, SwiperRef } from "antd-mobile";
import styles from "./index.module.css";
import { useBanners, screen_width } from "../data";
import { useNavigate } from "react-router-dom";

export interface BannerProps {
  /**
   * pic url，Banner图片地址
   */
  pic_url: string;
  /**
   * game id，Banner对应的game_id
   */
  game_id: number;
}

let is_pc:Boolean = screen_width()
function Banner() {
  const banners = useBanners();
  const ref = useRef<SwiperRef>(null);
  const navigate = useNavigate();


  if(is_pc){
    return (
      <Swiper
        autoplay
        loop
        style={{
          "--track-padding": "0 0 2vh",
          "--width": "95%",
        }}
        className={styles.root}
        ref={ref}
        indicator={(total, current) => (
          <div className={styles.indicators}>
            {Array.from(new Array(total)).map((item, index) => (
              <div
                key={index}
                className={current === index ? styles.active : ""}
                onClick={() => {
                  ref.current?.swipeTo(index);
                }}
              ></div>
            ))}
          </div>
        )}
      >
        {banners.map((item, index) => (
          <Swiper.Item
            key={index}
            className={styles.content}
            onClick={() => navigate(`/detail/${item.game_id}`)}
          >
            <img
              src={`${process.env.REACT_APP_ADMIN_URL}/${item.pic_url}`}
              alt=""
            ></img>
          </Swiper.Item>
        ))}
      </Swiper>
    );
  }
  else{
    return (
      <Swiper
        autoplay
        loop
        style={{
          "--track-padding": " 0 0 10px",
        }}
        ref={ref}
        indicator={(total, current) => (
          <div className={styles.indicators}>
            {Array.from(new Array(total)).map((item, index) => (
              <div
                key={index}
                className={current === index ? styles.active : ""}
                onClick={() => {
                  ref.current?.swipeTo(index);
                }}
              ></div>
            ))}
          </div>
        )}
      >
        {banners.map((item, index) => (
          <Swiper.Item
            key={index}
            className={styles.content}
            onClick={() => navigate(`/detail/${item.game_id}`)}
            style={{
              backgroundImage: `url(${process.env.REACT_APP_ADMIN_URL}/${item.pic_url})`,
              backgroundSize: "cover",
            }}
          ></Swiper.Item>
        ))}
      </Swiper>
    );
  }
}

export default Banner;
