import styles from "./index.module.css";
import { useState, useEffect } from "react";
import { Toast } from "antd-mobile";

import { screen_width } from "../data";

let is_pc:Boolean = screen_width();

function MyModal(props: {
    onClose: ()=>void;
}) {
    const [form, setForm] = useState({
        pelne: "",
        email: "",
        telefon: "",
        temat: "",
        pisz: ""
    });

    const [pelne_bordercolor, setpelne_Bordercolor] = useState('1px solid #ECECEC')
    const [email_bordercolor, setemail_Bordercolor] = useState('1px solid #ECECEC')
    const [telefon_bordercolor, settelefon_Bordercolor] = useState('1px solid #ECECEC')
    const [temat_bordercolor, settemat_Bordercolor] = useState('1px solid #ECECEC')
    const [pisz_bordercolor, setpisz_Bordercolor] = useState('1px solid #ECECEC')

    function change_border(value:string, setBordercolor: React.Dispatch<React.SetStateAction<string>>){
        if (!value){
            // console.log("Blur--------------------", value)
            setBordercolor("1px solid red")
        }else{
            setBordercolor("1px solid #ECECEC")
        }
    }
    return (
        <div className={styles["login-content"]}>
            <div className={styles["login-content-close"]} onClick={props.onClose}>
                <img src="/assets/close.svg" alt=""></img>
            </div>

            {is_pc?(
                <div className={styles["login-content-content"]}>
                    <p>Skontaktuj Się Z Nami</p>
                    
                    <div style={{display: "flex"}}>
                        <input 
                        placeholder="Pełne imię i nazwisko"
                        value={form.pelne}
                        className={styles["login-content-input"]}
                        onBlur={(ele)=>{
                            var value = (ele.target as HTMLInputElement)?.value
                            change_border(value, setpelne_Bordercolor)
                        }}
                        style={{marginRight: "2%", border: pelne_bordercolor}}
                        onInput={(ele)=>{
                            setForm({
                                ...form,
                                ...{pelne: (ele.target as HTMLInputElement)?.value},
                            })
                        }}

                        ></input>

                        <input
                        className={styles["login-content-input"]}
                        placeholder="Twój email"
                        value={form.email}
                        onBlur={(ele)=>{
                            var value = (ele.target as HTMLInputElement)?.value
                            change_border(value, setemail_Bordercolor)
                        }}
                        style={{border: email_bordercolor}}
                        onInput={(ele)=>{
                            setForm({
                                ...form,
                                ...{email: (ele.target as HTMLInputElement)?.value},
                            })
                        }}

                        ></input>
                    </div>

                    <div style={{display: "flex"}}>
                        <input
                        className={styles["login-content-input"]}
                        placeholder="Telefon"
                        value={form.telefon}
                        onBlur={(ele)=>{
                            var value = (ele.target as HTMLInputElement)?.value
                            change_border(value, settelefon_Bordercolor)
                        }}
                        style={{marginRight: "2%", border: telefon_bordercolor}}
                        onInput={(ele)=>{
                            setForm({
                                ...form,
                                ...{telefon: (ele.target as HTMLInputElement)?.value},
                            })
                        }}
                        ></input>

                        <input
                        className={styles["login-content-input"]}
                        placeholder="Temat"
                        value={form.temat}
                        onBlur={(ele)=>{
                            var value = (ele.target as HTMLInputElement)?.value
                            change_border(value, settemat_Bordercolor)
                        }}
                        style={{border: temat_bordercolor}}
                        onInput={(ele)=>{
                            setForm({
                                ...form,
                                ...{temat: (ele.target as HTMLInputElement)?.value},
                            })
                        }}
                        ></input>
                    </div>

                    <input
                    className={styles["login-content-input"]}
                    placeholder="Pisz wiadomość"
                    value={form.pisz}
                    onBlur={(ele)=>{
                        var value = (ele.target as HTMLInputElement)?.value
                        change_border(value, setpisz_Bordercolor)
                    }}
                    style={{lineHeight: "65px", border: pisz_bordercolor}}
                    onInput={(ele)=>{
                        setForm({
                            ...form,
                            ...{pisz: (ele.target as HTMLInputElement)?.value},
                        })
                    }}
                    ></input>

                    <div className={styles["login-content-confirm"]}
                    onClick={()=>{
                        // alert(111)
                        if (form.pelne && form.email && form.pisz && form.telefon && form.temat){
                            Toast.show({
                                icon: "success",
                                content: "Sukces",
                            })
                            props.onClose()
                        }else{
                            console.log("else form==", form)
                            Toast.show({
                                icon: "fail",
                                content: "Brakujące Dane",
                            })
                        }
                    }}
                    >Prześlij teraz</div>
                </div>
                
            ):(
                <div className={styles["login-content-content"]}>
                    <p>Skontaktuj Się Z Nami</p>
                    
                    <input
                    placeholder="Pełne imię i nazwisko"
                    value={form.pelne}
                    className={styles["login-content-input"]}
                    onBlur={(ele)=>{
                        var value = (ele.target as HTMLInputElement)?.value
                        change_border(value, setpelne_Bordercolor)
                    }}
                    style={{border: pelne_bordercolor}}
                    onInput={(ele)=>{
                        setForm({
                            ...form,
                            ...{pelne: (ele.target as HTMLInputElement)?.value},
                        })
                    }}

                    ></input>

                    <input
                    className={styles["login-content-input"]}
                    placeholder="Twój email"
                    value={form.email}
                    onBlur={(ele)=>{
                        var value = (ele.target as HTMLInputElement)?.value
                        change_border(value, setemail_Bordercolor)
                    }}
                    style={{border: email_bordercolor}}
                    onInput={(ele)=>{
                        setForm({
                            ...form,
                            ...{email: (ele.target as HTMLInputElement)?.value},
                        })
                    }}
                    ></input>

                    <input
                    className={styles["login-content-input"]}
                    placeholder="Telefon"
                    value={form.telefon}
                    onBlur={(ele)=>{
                        var value = (ele.target as HTMLInputElement)?.value
                        change_border(value, settelefon_Bordercolor)
                    }}
                    style={{border: telefon_bordercolor}}
                    onInput={(ele)=>{
                        setForm({
                            ...form,
                            ...{telefon: (ele.target as HTMLInputElement)?.value},
                        })
                    }}
                    ></input>

                    <input
                    className={styles["login-content-input"]}
                    placeholder="Temat"
                    value={form.temat}
                    onBlur={(ele)=>{
                        var value = (ele.target as HTMLInputElement)?.value
                        change_border(value, settemat_Bordercolor)
                    }}
                    style={{border: temat_bordercolor}}
                    onInput={(ele)=>{
                        setForm({
                            ...form,
                            ...{temat: (ele.target as HTMLInputElement)?.value},
                        })
                    }}
                    ></input>

                    <input
                    className={styles["login-content-input"]}
                    placeholder="Pisz wiadomość"
                    value={form.pisz}
                    onBlur={(ele)=>{
                        var value = (ele.target as HTMLInputElement)?.value
                        change_border(value, setpisz_Bordercolor)
                    }}
                    style={{lineHeight: "65px", border: pisz_bordercolor}}
                    onInput={(ele)=>{
                        setForm({
                            ...form,
                            ...{pisz: (ele.target as HTMLInputElement)?.value},
                        })
                    }}
                    ></input>

                    <div className={styles["login-content-confirm"]}
                    onClick={()=>{
                        if (form.pelne && form.email && form.pisz && form.telefon && form.temat){
                            Toast.show({
                                icon: "success",
                                content: "Sukces",
                            })
                            props.onClose()
                        }else{
                            console.log("else form==", form)
                            Toast.show({
                                icon: "fail",
                                content: "Brakujące Dane",
                            })
                        }
                    }}
                    >Prześlij teraz</div>
                </div>
            )}

        </div>

    );
  }
  
  export default MyModal;
