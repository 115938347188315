import { Fragment } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { androidId, onlineId } from "../data";
import GameList from "../GameList";
import GameNews from "../GameNews";
import Banner from "../Banner";
import { AppContext } from "../../App";
import GameCategory from "../GameCategory";
import Divider from "../Divider";

import { screen_width, useHomeNews } from "../data";

import GameNewsMobile from "../GameNews/index_mobile";
import Title from "../Title";
let is_pc:Boolean = screen_width();

function Home() {
  const navigate = useNavigate();
  const [, , strategy, setStrategy] = useOutletContext<AppContext>();

  const news = useHomeNews(4);

  if (is_pc){
    return (
      <Fragment>
        <Banner></Banner>
        <Divider />
        <GameList
          title="Nasze najlepsze gry online"
          type={onlineId}
          onClickTitle={() => {
            setStrategy("All");
            navigate("/online");
          }}
        ></GameList>
        <Divider />
        <GameList
          title="Nasze najlepsze gry na Androida"
          type={androidId}
          onClickTitle={() => {
            setStrategy("All");
            navigate("/android");
          }}
        ></GameList>
        <Divider />
        <GameNews></GameNews>
        <Divider />
        <GameCategory
          strategy={strategy}
          setStrategy={setStrategy}
          type={"horizontal"}
          gameType={onlineId}
        />
      </Fragment>
    );
  }else{
    return (
      <Fragment>
        <Banner></Banner>
        <GameList
          title="Nasze najlepsze gry online"
          type={onlineId}
          onClickTitle={() => {
            setStrategy("All");
            navigate("/online");
          }}
        ></GameList>
        <GameList
          title="Nasze najlepsze gry na Androida"
          type={androidId}
          onClickTitle={() => {
            setStrategy("All");
            navigate("/android");
          }}
        ></GameList>
  
        <Title
          title="Wiadomości o grach"
          onClick={() => navigate("/news")}
          right="Wszystkie"
        ></Title>
        <div
          style={{
            marginTop: "10px",
          }}
        >
          {news.map((item, index) => (
            <GameNewsMobile data={item} key={index}></GameNewsMobile>
          ))}
        </div>
      </Fragment>
    );
  }
  
}

export default Home;
