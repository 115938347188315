import styles from "./index.module.css";
import { useOutletContext, useNavigate } from "react-router-dom";
import { GameType, onlineId } from "../data";
import { AppContext } from "../../App";
export interface GameProps {
  /**
   * 游戏id，递增唯一
   */
  id: number;
  /**
   * 缩略图链接
   */
  thumbnail: string;
  /**
   * 游戏名称
   */
  title_pl: string;
  /**
   * 游戏链接
   */
  url: string;
  /**
   * 游戏类型，12: Online Games；13:Android Games
   */
  platform: GameType;
  /**
   * 游戏描述，可以为空
   */
  description_pl: string;
  /**
 * 游戏类型别
 */
  strategy_pl: string;
}

function GameCard(props: { data: GameProps; showDesc: boolean }) {
  const [, setShowLogin] = useOutletContext<AppContext>();

  const navigate = useNavigate();

  return (
    <div className={styles.root}>
      <div className={styles.thumbnail}>
        <img
          src={`${process.env.REACT_APP_ADMIN_URL}/${props.data.thumbnail}`}
          alt=""
          onClick={() => navigate(`/detail/${props.data.id}`)}
        ></img>
      </div>

      <div
        className={styles.title}
        // style={{
        //   height: props.showDesc ? "10%" : "20%",
        // }}
      >
        {props.data.title_pl}
      </div>
      {props.showDesc && (
        <>
          <div className={styles.description}>{props.data.description_pl}</div>
          <div className={styles.divider}></div>
        </>
      )}

      <div className={styles.play}>
        <button
          onClick={() => {
            if (localStorage.getItem("token")) {
              window.location.href = props.data.url;
            } else {
              setShowLogin(true);
            }
          }}
        >
          {props.data.platform === onlineId ? "Zagraj teraz" : "Pobierz teraz"}
        </button>
      </div>
    </div>
  );
}

export default GameCard;
