import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { AppContext } from "../../App";
import { onlineId, useGameDetail, useGameScreenshot } from "../data";
import GameCategory from "../GameCategory";
import GameDetail from "../GameDetail";
import GameList from "../GameList";
import styles from "./Detail.module.css";

import { screen_width } from "../data";
import { Fragment } from "react";
import GameDetailMobile from "../GameDetail/index_mobile";

let is_pc:Boolean = screen_width()
function Detail() {
  let { gameId } = useParams();
  const navigate = useNavigate();

  const gameDetail = useGameDetail(Number(gameId));

  const screenshot = useGameScreenshot(Number(gameId));

  const [, , strategy, setStrategy] = useOutletContext<AppContext>();

  if(is_pc){
    return (
      <>
        {gameDetail && (
          <div className={styles.root}>
            <div className={styles.left}>
              <GameDetail game={gameDetail} screenshot={screenshot}></GameDetail>
  
              <GameList
                title="Polecane przez nas gry"
                type={gameDetail.platform}
                onClickTitle={() =>
                  navigate(
                    gameDetail.platform === onlineId ? "/online" : "/android"
                  )
                }
              ></GameList>
            </div>
            <div className={styles.right}>
              <GameCategory
                strategy={strategy}
                setStrategy={setStrategy}
                type={"vertical"}
                gameType={gameDetail.platform}
              ></GameCategory>
            </div>
          </div>
        )}
      </>
    );
  }else{
    return (
      <Fragment>
        {gameDetail && (
          <GameDetailMobile game={gameDetail} screenshot={screenshot}></GameDetailMobile>
        )}
        {gameDetail && (
          <GameList
            title="Polecane przez nas gry"
            type={gameDetail.platform}
            onClickTitle={() =>
              navigate(gameDetail.platform === onlineId ? "/online" : "/android")
            }
          ></GameList>
        )}
      </Fragment>
    );
  }
}

export default Detail;
