import { useNavigate } from "react-router-dom";
import { GameNewsProps } from ".";
import { useAllNews } from "../data";
import Divider from "../Divider";
import styles from "./news.module.css";

import { screen_width } from "../data";
import { Fragment } from "react";

export interface GameNewsDetailProps extends GameNewsProps {
  /**
   * Content，新闻内容HTML
   */
  content: string;
}

let is_pc:Boolean = screen_width();


function News() {
  const more_news = useAllNews();
  const navigate = useNavigate();
  if(is_pc){
    return (
      <div>
        {more_news.map((item, index) => (
          <>
            {index > 0 && <Divider />}
            <div
              onClick={() => navigate(`/news/${item.id}`)}
              key={index}
              className={styles.item}
            >
              <div className={styles.left}>
                <img
                  src={`${process.env.REACT_APP_ADMIN_URL}/${item.pic_url}`}
                  alt=""
                  className={styles.img}
                ></img>
              </div>
              <div className={styles.right}>
                <div className={styles.title}>{item.title}</div>
                <div className={styles.content}>{item.abstract}</div>
              </div>
            </div>
          </>
        ))}
      </div>
    );
  }else{
    return (
      <div className={styles["more-news-root"]}>
        {more_news.map((item, index) => (
          <Fragment key={index}>
            <div onClick={() => navigate(`/news/${item.id}`)}>
              <img src={`${process.env.REACT_APP_ADMIN_URL}/${item.pic_url}`} alt=""></img>
              <p className={styles["news-title"]}>{item.title}</p>
              <p className={styles["news-content"]}>{item.abstract}</p>
            </div>
            {index % 2 === 0 && <div />}
          </Fragment>
        ))}
      </div>
    );
  }
}

export default News;
