import { Image } from "antd-mobile";
import { Link } from "react-router-dom";
import styles from "./index.module.css";

function Header(props: {
  active: boolean;
  onClick: () => void;
  onLogin: () => void;
  mobile_no: string | undefined;
}) {
  return (
    <>
      <div
        className={`lines ${props.active ? "active" : ""}`}
        onClick={props.onClick}
      >
        {[0, 1, 2].map((number) => (
          <div className={styles.line} key={number}></div>
        ))}
      </div>


      <div className={styles.logo}>
        <Link to="/">
          <Image src="/assets/logo.svg"></Image>
        </Link>
      </div>
      <div
        className={styles.login}
        onClick={() => props.mobile_no === undefined && props.onLogin()}
      >
        <span>{props.mobile_no ? props.mobile_no : "Zaloguj się"}</span>
        <Image src="/assets/user.svg" height={24} width={24}></Image>
      </div>
    </>
  );
}

export default Header;
