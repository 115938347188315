import { useNavigate } from "react-router-dom";
import { useHomeNews } from "../data";
import Title from "../Title";
import styles from "./index.module.css";

export interface GameNewsProps {
  /**
   * news id，新闻 id
   */
  id: number;
  /**
   * Abstract，新闻摘要
   */
  abstract: string;
  /**
   * pic_url，新闻图片链接
   */
  pic_url: string;
  /**
   * Title，新闻标题
   */
  title: string;
}

function GameNews() {
  const news = useHomeNews(4);
  const navigate = useNavigate();
  return (
    <>
      <Title
        title="Wiadomości o grach"
        onClick={() => navigate("/news")}
        right="Wszystkie"
      ></Title>

      <div className={styles.root}>
        {news.map((item, index) => (
          <div
            key={index}
            className={styles.content}
            onClick={() => navigate(`/news/${item.id}`)}
          >
            <img
              src={`${process.env.REACT_APP_ADMIN_URL}/${item.pic_url}`}
              className={styles.pic_url}
              alt=""
            ></img>
            <div className={styles.title}>{item.title}</div>
            <div className={styles.abstract}>{item.abstract}</div>
          </div>
        ))}
      </div>
    </>
  );
}


export default GameNews;



