import { Image, Space } from "antd-mobile";
import { useOutletContext } from "react-router-dom";
import { AppContext } from "../../App";
import { onlineId } from "../data";

import styles from "./index.module.css";

import { GamePropsMobile } from "../GameCard/index_mobile";

export interface GameDetailProps extends GamePropsMobile {
  /**
   * 游戏描述，可以为空
   */
  description_pl: string;
  /**
   * 游戏分数，0-5分，5分满分，默认0分
   */
  score: number;
  /**
   * 策略，枚举值，游戏类型，如：冒险类游戏，益智类游戏等，可以为空
   */
  strategy_pl: string;
}

/**
 * game_screenshot
 */
export interface GameScreenshot {
  /**
   * pic url，游戏截图图片地址
   */
  pic_url: string;
}

function GameDetailMobile(props: {
  game: GameDetailProps;
  screenshot: Array<GameScreenshot>;
}) {
  const starTotalWidth = 18;
  const starFill = Math.floor(props.game.score);
  const starWidth = starTotalWidth * (1 - (props.game.score - starFill));

  const [, setShowLogin] = useOutletContext<AppContext>();

  return (
    <div className={styles["detail-content"]}>
      <div>
        <Image
          height={"100%"}
          fit="cover"
          src={`${process.env.REACT_APP_ADMIN_URL}/${props.game.thumbnail}`}
          alt=""
        ></Image>
        <div className={styles["right"]}>
          <p className={styles["detail-content-title"]}>{props.game.title_pl}</p>
          <div>
            <button className={styles["detail-content-action"]}>{props.game.strategy_pl.split(",")[0]}</button>
          </div>
          <div className={styles.score}>
            <div>
              <Space>
                {Array.from(new Array(starFill).keys()).map((item) => (
                  <Image
                    key={item}
                    width={18}
                    height={18}
                    src="/assets/star.svg"
                    alt=""
                  ></Image>
                ))}
                {starWidth && (
                  <Image
                    style={{ clipPath: `inset(0 ${starWidth}px 0 0)` }}
                    width={starTotalWidth}
                    height={starTotalWidth}
                    src="/assets/star.svg"
                    alt=""
                  ></Image>
                )}
              </Space>
            </div>
            <span>{props.game.score}</span>
          </div>
        </div>
      </div>
      <button
        className={styles["detail-content-play-now"]}
        onClick={() => {
          if (localStorage.getItem("token")) {
            window.location.href = props.game.url;
          } else {
            setShowLogin(true);
          }
        }}
      >
        {props.game.platform === onlineId ? "Zagraj teraz" : "Pobierz teraz"}
      </button>
      <p className={styles["detail-content-desc"]}>{props.game.description_pl}</p>
      <div className={styles["detail-content-images"]}>
        <Space>
          {props.screenshot.map((item, index) => (
            <img
              width={64}
              height={64}
              src={`${process.env.REACT_APP_ADMIN_URL}/${item.pic_url}`}
              key={index}
              alt=""
            />
          ))}
        </Space>
      </div>
    </div>
  );
}

export default GameDetailMobile;
