import styles from "./App.module.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Menu from "./components/Menu";
import { useState, useEffect } from "react";
import { menus, screen_width, api_login_log_post } from "./components/data";
import Login, { UserToken } from "./components/Login";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import GameCategory, { GameStrategy } from "./components/GameCategory";
import GameCategoryMobile from "./components/GameCategory/index_mobile";

export type AppContext = [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  GameStrategy,
  React.Dispatch<React.SetStateAction<GameStrategy>>
];

let is_pc:Boolean = screen_width()


function App() {
  const [active, setActive] = useState(false);

  const [showLogin, setShowLogin] = useState(false);
  const [strategy, setStrategy] = useState<GameStrategy>("All");
  const [strategy_mobile, setStrategy_mobile] = useState<GameStrategy>("All");

  const navigate = useNavigate();

  const _token = window.localStorage.getItem("token");

  const [token, setToken] = useState<UserToken | undefined>(
    _token ? JSON.parse(_token) : undefined
  );

  const location = useLocation();

  const showStrategy_mobile = ["/online", "/android"].includes(location.pathname);
  const showStrategy = [, "/android"].includes(location.pathname);


  
  function refurbish(){
    const token2 = window.localStorage.getItem("token");
    if (token2){
      console.log("已经登录！")
      let url_parmas =  window.location.search
      if (!url_parmas.includes("pmsisdn")){
        api_login_log_post(JSON.parse(token2).token)
      }
      
      let status = JSON.parse(token2).status
      if (status === 1){
        const _token:UserToken = {
          mobile_no: JSON.parse(token2).mobile_no,
          token: JSON.parse(token2).token,
          status: JSON.parse(token2).status
        }
        setToken(_token)
      }else{
        window.localStorage.removeItem("token")
      }
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      refurbish();

      // mobile
      if (!is_pc){
        // setActive(true)
        console.log("mobile=====>is_pc=", is_pc)
      }else{
        console.log("pc=====>is_pc=", is_pc)
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [])

  if (is_pc){
    return (
      <div className={styles.App}>
        {showStrategy ? (
          <Outlet context={[showLogin, setShowLogin, strategy, setStrategy]} />
        ) : (
          <>
            <div className={styles.navbar}>
              <Header
                active={active}
                onClick={() => setActive(!active)}
                onLogin={() => setShowLogin(true)}
                mobile_no={token?.mobile_no}
              ></Header>
            </div>
            
  
            {active && (
              <Menu
                onSelect={(item) => {
                  setActive(false);
                  navigate(item.link);
                }}
                menus={menus}
              ></Menu>
            )}
  
            <div 
              className={styles.body} 
              onClick={() => setActive(false)}
              style={is_pc?{minHeight: showStrategy ? "76vh" : "81vh"}:{}}
              >
              <Outlet
                context={[showLogin, setShowLogin, strategy, setStrategy]}
              />
            </div>
  
            {showLogin && (
              <Login
                onClose={() => setShowLogin(false)}
                setToken={setToken}
              ></Login>
            )}
            <div className={styles.footer}>
              <Footer></Footer>
            </div>
          </>
        )}
      </div>
    );
  }else{
    // mobile
    return (
      <div className={styles.App}>
        <div className={styles.navbar}>
          <Header
            active={active}
            onClick={() => setActive(!active)}
            onLogin={() => setShowLogin(true)}
            mobile_no={token?.mobile_no}
          ></Header>
        </div>
        {showStrategy_mobile && (
          <div className={styles["category-root"]}>
            <GameCategoryMobile
              strategy={strategy_mobile}
              setStrategy={setStrategy_mobile}
            ></GameCategoryMobile>
          </div>
        )}
        {active && (
          <Menu
            onSelect={(item) => {
              setActive(false);
              navigate(item.link);
            }}
            menus={menus}
          ></Menu>
        )}
  
        <div
          className={styles.body}
          onClick={() => setActive(false)}
          style={{
            minHeight: showStrategy_mobile ? "76vh" : "81vh",
          }}
        >
          <Outlet context={[showLogin, setShowLogin, strategy_mobile, setStrategy_mobile]} />
        </div>
        {showLogin && (
          <Login onClose={() => setShowLogin(false)} setToken={setToken}></Login>
        )}
        <div className={styles.footer}>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default App;
