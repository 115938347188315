import { Image } from "antd-mobile";
import styles from "./index.module.css";
import { all_category } from "../data";

export type GameStrategyMobile =
  | "All"
  | "Akcji"
  | "Przygoda"
  | "Relaksacyjne"
  | "Puzzle"
  | "Wyścig"
  | "Strzelanki"
  | "Sport"
  | "Strategia";
export interface GameCategoryProps {
  icon: string;
  strategy: GameStrategyMobile;
}

function GameCategoryMobile(props: {
  strategy: GameStrategyMobile;
  setStrategy: React.Dispatch<React.SetStateAction<GameStrategyMobile>>;
}) {
  if (props.strategy === undefined) {
    props.setStrategy("All");
  }
  return (
    <>
      {all_category.map((item, index) => (
        <div
          className={`${styles["category-item"]} ${
            props.strategy === item.strategy ? styles.active : ""
          }`}
          key={index}
          onClick={() => props.setStrategy(item.strategy)}
        >
          <Image width={20} height={20} src={item.icon}></Image>
          <span>{item.strategy}</span>
        </div>
      ))}
    </>
  );
}

export default GameCategoryMobile;
