import { Link, useLocation, useOutletContext } from "react-router-dom";
import GameCategory from ".";
import { AppContext } from "../../App";
import { GameType, onlineId, useCategory } from "../data";
import GameCard from "../GameCard";
import styles from "./all.module.css";

import { Fragment } from "react";


function All(props: { type: GameType }) {
  const [, , strategy, setStrategy] = useOutletContext<AppContext>();

  const location = useLocation();
  const showCategory = ["/online", "/android"].includes(location.pathname);
  const data = useCategory(props.type, strategy, showCategory);

  return (
    <div className={styles.root}>
      <div className={styles.ooo}></div>
      <div className={styles.left}>
        <Link to="/" onClick={() => setStrategy("All")}>
          <img src="/assets/logo.svg" className={styles.logo} alt=""></img>
        </Link>
        <div className={styles["game-type"]}>
          {props.type === onlineId ? "Online games" : "Android games"}
        </div>
        <div className={styles["strategy-content"]}>
          <GameCategory
            type={"vertical"}
            strategy={strategy}
            setStrategy={setStrategy}
            gameType={props.type}
          />
        </div>
      </div>
      <div className={styles.right}>
        
        <div className={styles.content}>
          <Fragment>

          {data.map((item, index) => (
            <GameCard data={item} showDesc={false} />
          ))}
          </Fragment>
        </div>
      </div>
    </div>
  );
}

export default All;
