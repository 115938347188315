import { useEffect, useState } from "react";
import { BannerProps } from "./Banner";
import { GameProps } from "./GameCard";
import { GameCategoryProps, GameStrategy } from "./GameCategory";
import { GameDetailProps, GameScreenshot } from "./GameDetail";
import { GameNewsProps } from "./GameNews";
import { GameNewsDetailProps } from "./GameNews/News";
import { UserToken, URLParam } from "./Login";
import { MenuProps } from "./Menu";

let baseURL = process.env.REACT_APP_SERVER_URL;

//
export const onlineId = 0;
export const androidId = 1;

export type GameType = 0 | 1;

export const useGames = (id: number) => {
	const [data, setData] = useState<Array<GameProps>>();
	useEffect(() => {
		fetch(`${baseURL}/api/groups/games?groupId=${id}`)
			.then((res) => res.json())
			.then((res) => {
				setData(res.data.length > 0 ? res.data[0].game_list : []);
			});
	}, [id]);
	return data || [];
};

export const useHomeNews = (count: number) => {
	const [data, setData] = useState<Array<GameNewsProps>>();
	useEffect(() => {
		fetch(`${baseURL}/api/news?count=${count}`)
			.then((res) => res.json())
			.then((res) => {
				setData(res.data);
			});
	}, [count]);
	return data || [];
};

export const useAllNews = () => {
	const [data, setData] = useState<Array<GameNewsProps>>();

	useEffect(() => {
		fetch(`${baseURL}/api/news`)
			.then((res) => res.json())
			.then((res) => {
				setData(res.data);
			});
	}, []);
	return data || [];
};

export const useNewsDetail = (news_id: number | undefined) => {
	const [data, setData] = useState<GameNewsDetailProps>();
	useEffect(() => {
		if (news_id) {
			fetch(`${baseURL}/api/news/${news_id}`)
				.then((res) => res.json())
				.then((res) => {
					setData(res.data);
				});
		}
	}, [news_id]);
	return data;
};

export const menus: Array<MenuProps> = [
	{
		title: "Gry online",
		link: "/online",
	},
	{
		title: "Gry na Androida",
		link: "/android",
	},
	{
		title: "Wiadomości o grach",
		link: "/news",
	},
];

export const useGameDetail = (gameId: number) => {
	const [data, setData] = useState<GameDetailProps>();
	useEffect(() => {
		fetch(`${baseURL}/api/games/${gameId}`)
			.then((res) => res.json())
			.then((res) => {
				setData(res.data);
			});
	}, [gameId]);
	return data;
};

export const useGameScreenshot = (gameId: number) => {
	const [data, setData] = useState<Array<GameScreenshot>>();
	useEffect(() => {
		fetch(`${baseURL}/api/screenshot/${gameId}`)
			.then((res) => res.json())
			.then((res) => {
				setData(res.data);
			});
	}, [gameId]);
	return data || [];
};

export const all_category: Array<GameCategoryProps> = [
	{
		icon: "/assets/all.svg",
		strategy: "All",
	},
	{
		icon: "/assets/Action.png",
		strategy: "Akcji",
	},
	{
		icon: "/assets/Adventure.png",
		strategy: "Przygoda",
	},
	{
		icon: "/assets/Casual.png",
		strategy: "Relaksacyjne",
	},
	{
		icon: "/assets/Puzzle.png",
		strategy: "Puzzle",
	},
	{
		icon: "/assets/Racing.png",
		strategy: "Wyścig",
	},
	{
		icon: "/assets/Shooter.png",
		strategy: "Strzelanki",
	},
	{
		icon: "/assets/Sports.png",
		strategy: "Sport",
	},
	{
		icon: "/assets/Strategy.png",
		strategy: "Strategia",
	},
];

export const useBanners = () => {
	const [data, setData] = useState<Array<BannerProps>>();
	useEffect(() => {
		fetch(`${baseURL}/api/banners?web_no=0`)
			.then((res) => res.json())
			.then((res) => {
				setData(res.data);
			});
	}, []);
	return data || [];
};

export const login = (
	mobile_no: string,
	password: string,
	callback: (msg: string, token: UserToken, registered: boolean) => void
) => {
	let body = new FormData();
	body.set("mobile_no", mobile_no);
	body.set("password", password);
	body.set("area_no", "48");
	fetch(`${baseURL}/api/login`, {
		body,
		method: "POST",
	})
		.then((res) => res.json())
		.then((res) => {
			callback(res.msg, res.data, res.registered);
		});
};

export const register = (
	mobile_no: string,
	password: string,
	callback: (code: number, msg: string) => void
) => {
	let body = new FormData();
	body.set("mobile_no", mobile_no);
	body.set("password", password);
	body.set("area_no", "86");
	fetch(`${baseURL}/api/register`, {
		body,
		method: "POST",
	})
		.then((res) => res.json())
		.then((res) => {
			callback(res.code, res.msg);
		});
};

export const useCategory = (
	game_type: GameType,
	game_strategy: GameStrategy,
	showCategory: boolean
) => {
	const [data, setData] = useState<Array<GameProps>>([]);
	useEffect(() => {
		if (showCategory) {
			fetch(
				`${baseURL}/api/strategy_games_pl?game_type=${game_type}&game_strategy=${game_strategy}`
			)
				.then((res) => res.json())
				.then((res) => {
					setData(res.data);
				});
		}
	}, [game_strategy, game_type, showCategory]);
	return data;
};

export const unsubscribed = (data: URLParam) => {
	// let baseURL = "http://127.0.0.1:8080";
	let body = JSON.stringify(data);
	return fetch(`${baseURL}/api/unsubscribed`, {
		body,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
	}).then((res) => res.json());
};

// get screen.width
export const screen_width = () => {
	let width = window.screen.width;
	return width > 500 ? true : false;
};

export const api_login_log_post = (data: string) => {
	console.log("api_login_log_post===>", data);
	let body = JSON.stringify({ token: data });
	return fetch(`${baseURL}/api/login-log`, {
		body,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
	}).then((res) => {
		res.json();
	});
};
