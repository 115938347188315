import styles from "./index.module.css";
import { all_category, GameType, onlineId } from "../data";
import { useLocation, useNavigate } from "react-router-dom";

export type GameStrategy =
  | "All"
  | "Akcji"
  | "Przygoda"
  | "Relaksacyjne"
  | "Puzzle"
  | "Wyścig"
  | "Strzelanki"
  | "Sport"
  | "Strategia";
export interface GameCategoryProps {
  icon: string;
  strategy: GameStrategy;
}

function GameCategory(props: {
  strategy: GameStrategy;
  setStrategy: React.Dispatch<React.SetStateAction<GameStrategy>>;
  type: "horizontal" | "vertical";
  gameType: GameType;
}) {
  if (props.strategy === undefined) {
    props.setStrategy("All");
  }

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div
      className={
        props.type === "horizontal"
          ? styles["root-horizontal"]
          : styles["root-vertical"]
      }
    >
      {all_category.map((item, index) => (
        <div
          className={`${
            props.type === "horizontal"
              ? styles["category-item-horizontal"]
              : styles["category-item-vertical"]
          } ${props.strategy === item.strategy ? styles.active : ""}`}
          key={index}
          onClick={() => {
            props.setStrategy(item.strategy);
            debugger;
            if (!["/online", "/android"].includes(location.pathname)) {
              navigate(props.gameType === onlineId ? "/online" : "/android");
            }
          }}
        >
          <img
            src={item.icon}
            className={
              props.type === "horizontal"
                ? styles["horizontal-icon"]
                : styles[item.strategy === "All" ? "all-icon" : "vertical-icon"]
            }
            alt=""
          ></img>
          <span className={styles.strategy}>{item.strategy} gry</span>
        </div>
      ))}
    </div>
  );
}

export default GameCategory;
