import { Fragment } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { AppContext } from "../../App";
import { GameType, useCategory, useGames } from "../data";
import GameCard from "../GameCard";
import Title from "../Title";
import styles from "./index.module.css";

import GameCardMobile from "../GameCard/index_mobile";
import { screen_width } from "../data";

let is_pc:Boolean = screen_width();

function GameList(props: {
  title?: string;
  type: GameType;
  onClickTitle: () => void;
}) {
  const games = useGames(props.type);
  const location = useLocation();

  const [, , strategy] = useOutletContext<AppContext>();

  const showCategory = ["/online", "/android"].includes(location.pathname);
  const categoryGames = useCategory(
    props.type,
    strategy === undefined ? "All" : strategy,
    showCategory
  );
  return (
    <Fragment>
      {props.title && (
        <Title
          title={props.title}
          onClick={props.onClickTitle}
          right="Wszystkie"
        ></Title>
       
      )}
      {is_pc?(
        <div className={styles.root}>
          {(showCategory ? categoryGames : games).map((item, index) => (
            <GameCard data={item} key={index} showDesc={true}></GameCard>
          ))}
        </div>

      ):(
        <div
          style={{
            marginTop: "10px",
          }}
        >
          {(showCategory ? categoryGames : games).map((item, index) => (
            <GameCardMobile data={item} key={index}></GameCardMobile>
          ))}
        </div>
      )}

      
    </Fragment>
  );
}

export default GameList;
