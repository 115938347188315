import MyModal from "./my_modal";
import { useState, useEffect } from "react";

const terms:string = "https://pl.gameplus.vip/terms_and_conditions.html"
const privacy:string = "https://plm.gameplus.vip/privacy_policy.html"



function Footer() {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
    {showModal && (
      <MyModal
      onClose={()=>{setShowModal(false)}}></MyModal>
    )}
    REG: <a href={terms}> Zasady i Warunki</a> - <a href={privacy}> Polityka prywatności</a>
      <p style={{marginTop: "2px", marginBottom: "2px"}}>Skontaktuj się z nami: customer@gameplus.vip <span style={{color: "cornflowerblue", textDecoration: "underline"}} onClick={()=>{
        setShowModal(true)
        }}>Skontaktuj Się Z Nami</span></p>
      <p style={{marginTop: "2px", marginBottom: "2px"}}>Copyright © 2022 Gameplus. Wszelkie prawa zastrzeżone.</p>
    </>
  );
}

export default Footer;
