import styles from "./index.module.css";
import { useNavigate, useOutletContext } from "react-router-dom";
import { GameType, onlineId } from "../data";
import { AppContext } from "../../App";
export interface GamePropsMobile {
  /**
   * 游戏id，递增唯一
   */
  id: number;
  /**
   * 缩略图链接
   */
  thumbnail: string;
  /**
   * 游戏名称
   */
  title_pl: string;
  /**
   * 游戏链接
   */
  url: string;

  /**
   * 游戏类型，12: Online Games；13:Android Games
   */
  platform: GameType;

  /**
 * 游戏类型别
 */
  strategy_pl: string;
}

function GameCardMobile(props: { data: GamePropsMobile }) {
  const navigate = useNavigate();

  const [, setShowLogin] = useOutletContext<AppContext>();

  return (
    <div className={styles.root}>
      <img
        className={styles.img}
        src={`${process.env.REACT_APP_ADMIN_URL}/${props.data.thumbnail}`}
        onClick={() => navigate(`/detail/${props.data.id}`)}
      ></img>
      <div className={styles.action}>
        <div>{props.data.title_pl}</div>
        <button>{props.data.strategy_pl}</button>
      </div>
      <button
        className={styles.play}
        onClick={() => {
          if (localStorage.getItem("token")) {
            window.location.href = props.data.url;
          } else {
            setShowLogin(true);
          }
        }}
      >
        {props.data.platform === onlineId ? "Zagraj teraz" : "Pobierz teraz"}

      </button>
    </div>
  );
}

export default GameCardMobile;
